import IO from 'components/io'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import variant from 'styles/variant'
import { system } from 'styled-system'

const Animated = styled.div(
  system({
    delay: {
      property: 'transitionDelay',
      scale: 'transitions',
    },
  }),
  system({
    duration: {
      property: 'transitionDuration',
      scale: 'transitions',
    },
  }),
  variant({
    animation: {
      fadeIn: {
        opacity: 0,
        transform: 'scale(0.99) translateY(5%)',
      },
    },
  }),
  variant({
    isVisible: {
      true: {
        opacity: 1,
        transform: 'none',
      },
    },
  })
)

Animated.defaultProps = {
  animation: 'fadeIn',
  duration: 4,
}

const Revealed = ({ containerProps, ...props }) => (
  <IO {...containerProps}>
    {({ isVisible, hasBeenVisible }) => (
      <Animated isVisible={isVisible || hasBeenVisible} {...props} />
    )}
  </IO>
)

Revealed.propTypes = {
  containerProps: PropTypes.object,
}

export { Revealed }
export default Animated
