import Animated, { Revealed } from 'components/animated'
import Box, { CardLink, Content, Flex, Grid, Section } from 'components/box'
import convertImageMap from 'helpers/convertImageMap'
import IO from 'components/io'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Screenshot from 'components/screenshot'
import Text, { Heading, Lead } from 'components/text'
import { Circle, Square, Triangle } from 'components/icon'
import { StaticQuery, graphql } from 'gatsby'

const ProductSection = ({ data }) => (
  <Section bg="neutral-700" mb={-5}>
    <Content pt={[5, 5, 6]} pb={6}>
      <Heading as={Revealed} color="white" level={2} measure="wide">
        Discover our solutions
      </Heading>
      <Lead as={Revealed} color="neutral-100" delay={1} measure="wide" mt={1}>
        Built and designed with respect to your intent.
      </Lead>
      <Products data={data} />
    </Content>
  </Section>
)

ProductSection.propTypes = {
  data: PropTypes.object.isRequired,
}

const Products = ({ data }) => {
  const { launcher, browser, timer } = data
  launcher.screen = convertImageMap(launcher.screens)['Essentials']
  browser.screen = convertImageMap(browser.screens)['Browser']
  timer.screen = convertImageMap(timer.screens)['Timer']

  return (
    <IO>
      {({ isVisible, hasBeenVisible }) => (
        <Grid columns={[1, 2, 3]} gridGap={[3, 3, 4]} mt={[4, 5]}>
          <Fragment>
            <Animated
              as={Box}
              gridColumn={['1', '1/-1', '1']}
              delay={1}
              isVisible={isVisible || hasBeenVisible}
            >
              <CardLink
                bg="neutral-050"
                border={0}
                borderRadius={2}
                display="flex"
                height="100%"
                justifyContent="space-between"
                flexDirection={['column', 'row-reverse', 'column']}
                elevation={2}
                overflow="hidden"
                to="/launcher"
              >
                <Box
                  bg="white"
                  flexGrow={1}
                  p={3}
                  width={['auto', 1 / 2, 'auto']}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Heading
                      alignItems="center"
                      color="blue"
                      display="inline-flex"
                      level={6}
                    >
                      <Square mr={3} opacity={0.5} />
                      Launcher
                    </Heading>
                    <Text
                      color="blue"
                      fontSize={4}
                      fontWeight="medium"
                      lineHeight={0}
                      transform="rotate(-45deg)"
                      mt={-1}
                      mr={-1}
                    >
                      →
                    </Text>
                  </Flex>
                  <Text
                    as={Lead}
                    display={['none', 'flex', 'none']}
                    fontWeight="medium"
                    mb={1}
                    mt={2}
                  >
                    {launcher.tagline}
                  </Text>
                  <Text as="p" color="neutral-600" mt={2}>
                    {launcher.description}
                  </Text>
                </Box>
                <Box
                  p={4}
                  pb={0}
                  maxHeight={['12rem', 'narrow', '12rem']}
                  overflow="hidden"
                  width={['auto', 1 / 2, 'auto']}
                >
                  <Box maxWidth="narrow" mx="auto">
                    <Screenshot
                      aspectRatio="16:9"
                      borderRadius={3}
                      screen={launcher.screen}
                    />
                  </Box>
                </Box>
              </CardLink>
            </Animated>
            <Animated
              as={Box}
              delay={2}
              isVisible={isVisible || hasBeenVisible}
            >
              <CardLink
                bg="neutral-050"
                border={0}
                borderRadius={2}
                display="flex"
                height="100%"
                justifyContent="space-between"
                flexDirection="column"
                elevation={2}
                overflow="hidden"
                to="/browser"
              >
                <Box bg="white" flexGrow={1} p={3}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Heading
                      alignItems="center"
                      color="purple"
                      display="inline-flex"
                      level={6}
                    >
                      <Circle mr={3} opacity={0.5} />
                      Browser
                    </Heading>
                    <Text
                      color="purple"
                      fontSize={4}
                      fontWeight="medium"
                      lineHeight={0}
                      transform="rotate(-45deg)"
                      mt={-1}
                      mr={-1}
                    >
                      →
                    </Text>
                  </Flex>
                  <Text as="p" color="neutral-600" mt={2}>
                    {browser.description}
                  </Text>
                </Box>
                <Box p={4} pb={0} maxHeight="12rem" overflow="hidden">
                  <Box maxWidth="narrow" mx="auto">
                    <Screenshot aspectRatio="5:4" screen={browser.screen} />
                  </Box>
                </Box>
              </CardLink>
            </Animated>
            <Animated
              as={Box}
              delay={3}
              isVisible={isVisible || hasBeenVisible}
            >
              <CardLink
                bg="neutral-050"
                border={0}
                borderRadius={2}
                display="flex"
                height="100%"
                justifyContent="space-between"
                flexDirection="column"
                elevation={2}
                overflow="hidden"
                to="/timer"
              >
                <Box bg="white" flexGrow={1} p={3}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Heading
                      alignItems="center"
                      color="red"
                      display="inline-flex"
                      level={6}
                    >
                      <Triangle mr={3} opacity={0.5} />
                      Timer
                    </Heading>
                    <Text
                      color="red"
                      fontSize={4}
                      fontWeight="medium"
                      lineHeight={0}
                      transform="rotate(-45deg)"
                      mt={-1}
                      mr={-1}
                    >
                      →
                    </Text>
                  </Flex>
                  <Text as="p" color="neutral-600" mt={2}>
                    {timer.description}
                  </Text>
                </Box>
                <Box p={4} pb={0} maxHeight="12rem" overflow="hidden">
                  <Box maxWidth="narrow" mx="auto">
                    <Screenshot aspectRatio="5:4" screen={timer.screen} />
                  </Box>
                </Box>
              </CardLink>
            </Animated>
          </Fragment>
        </Grid>
      )}
    </IO>
  )
}

Products.propTypes = {
  data: PropTypes.object.isRequired,
}

const Query = props => (
  <StaticQuery
    query={graphql`
      query ProductsQuery {
        launcher: launcherJson {
          tagline
          description
          screens {
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        browser: browserJson {
          description
          screens {
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        timer: timerJson {
          description
          screens {
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    {...props}
  />
)

export const ProductList = () => (
  <Query render={data => <Products data={data} />} />
)

const Solutions = () => (
  <Query render={data => <ProductSection data={data} />} />
)

export default Solutions
