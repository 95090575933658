import Button, { SecondaryButton } from 'components/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { AppContext } from 'store/app'
import { Content, Flex, Section } from 'components/box'
import { Heading, Lead } from 'components/text'
import { Link } from 'gatsby'
import { Revealed } from 'components/animated'
import { StaticQuery, graphql } from 'gatsby'

const CallToAction = ({ data }) => {
  const { focusSubscriptionForm } = useContext(AppContext).appState
  const { page } = data
  const { mountains } = page.images

  return (
    <Section
      bg="neutral-600"
      color="white"
      minHeight="50vh"
      textAlign="center"
      mb={-5}
      pb={5}
    >
      <Img
        alt={mountains.title}
        draggable={false}
        fluid={mountains.image.childImageSharp.fluid}
        style={{
          bottom: 0,
          height: '100%',
          left: 0,
          opacity: 0.25,
          position: 'absolute',
          width: '100%',
          zIndex: 0,
        }}
      />
      <Content my={5} position="relative">
        <Heading
          as={Revealed}
          forwardAs="h2"
          color="white"
          measure="narrow"
          mx="auto"
        >
          Interested?
        </Heading>
        <Lead
          as={Revealed}
          delay={1}
          forwardAs="p"
          color="neutral-200"
          mb={4}
          measure="narrow"
          mt={1}
          mx="auto"
        >
          Join us in shaping the future of Intent.
        </Lead>
        <Flex
          as={Revealed}
          delay={2}
          alignItems="center"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          mx="auto"
        >
          <Button
            color="neutral"
            onClick={focusSubscriptionForm}
            size="large"
            maxWidth="tight"
            width={['100%', 'auto']}
          >
            Join Beta
          </Button>
          <SecondaryButton
            as={Link}
            color="neutral"
            size="large"
            to="/contact"
            maxWidth="tight"
            width={['100%', 'auto']}
          >
            Contact us
          </SecondaryButton>
        </Flex>
      </Content>
    </Section>
  )
}

CallToAction.propTypes = {
  data: PropTypes.object.isRequired,
}

const Query = () => (
  <StaticQuery
    query={graphql`
      query CallToAction {
        page: homeJson {
          images {
            mountains: hero {
              title
              image {
                childImageSharp {
                  fluid(
                    duotone: { highlight: "#1F2933", shadow: "#4D5966" }
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <CallToAction data={data} />}
  />
)

export default Query
