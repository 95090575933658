import Box, { Card } from 'components/box'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'

const Screenshot = ({ aspectRatio, screen, ...props }) => {
  const dims = []
  if (aspectRatio instanceof Array) {
    aspectRatio.map(ratio => dims.push(ratio.split(':')))
  } else {
    dims.push(aspectRatio.split(':'))
  }

  const pb = dims.map(dim => {
    const [l, w] = dim
    return `${(l / w) * 100}%`
  })

  return (
    <Card bg="white" overflow="hidden" pb={pb} position="relative" {...props}>
      <Box position="absolute" top="0" right="0" bottom="0" left="0">
        <Img style={{ height: '100%' }} {...screen} />
      </Box>
    </Card>
  )
}

Screenshot.propTypes = {
  screen: PropTypes.object.isRequired,
  aspectRatio: PropTypes.oneOfType([
    PropTypes.oneOf(['1:1', '16:9', '9:21', '5:4']),
    PropTypes.arrayOf(PropTypes.oneOf(['1:1', '16:9', '9:21', '5:4'])),
  ]),
}

Screenshot.defaultProps = {
  aspectRatio: '1:1',
  border: 1,
  borderColor: 'transparent',
  borderRadius: 2,
  elevation: 4,
}

export default Screenshot
